export const usersMixin = {
    data() {
        return {
            loading: false,
            form: {
                name: null,
                email: null,
                password: null,
                role: null,
            },
            roles: null,
        };
    },
    methods: {
        clearForm() {
            for (const key in this.form) {
                this.form[key] = null;
            }
        },
        async fetchRoles() {
            try {
                const { data } = await this.axios.get('/users/create');
                this.roles = data.roles;
            } catch (ex) {
                console.log('cant fetch users: ' + ex);
            }
        },
        async fetchUser() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`/users/${this.$route.params.id}/edit`);
                this.roles = data.roles;

                for (const field in this.form) {
                    this.form[field] = data.user[field];
                }

                this.form.role = data.user_role.id;
                this.loading = false;
            } catch (ex) {
                console.log('cant find user: ' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
    },
};
